import {
  useCallback,
  useMemo,
  useState,
  Fragment,
  ReactNode,
  ReactElement,
} from 'react'
// Hooks
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useIsAuthenticated } from 'modules/auth/hooks'
import { useUser, useUserStorage } from 'modules/user/hooks'
import { useSubscription } from 'modules/subscription/hooks'
// Components
import {
  List,
  ListItemText,
  ListItemIcon,
  Collapse,
  Divider,
  useTheme,
} from '@mui/material'
import { Translation } from 'modules/core/components/Translation'
import { Tooltip } from 'modules/core/styled'
// Constants
import { userRoles } from 'modules/user/constants'
import { routeNames } from 'modules/core/constants'
import { ENABLE_SUBSCRIPTION } from 'modules/core/constants'
// Utils
import { doesUserHaveRole, doesUserHaveTierAccess } from 'modules/user/utils'
// Types
import { IconName } from 'modules/core/components/Icon/Icon.interface'
// Images
import LogoImageSrc from 'assets/images/logo.png'

import {
  Drawer,
  DrawerHeader,
  ToggleIcon,
  ToggleWrapper,
  LogoWrapper,
  NavExpandIcon,
  FlexStretch,
  ListItemButtonStyled,
  BottomBlock,
  NavIcon,
} from './Nav.styled'
import { NavProps } from './Nav.interface'

const ANALYSIS_NAV_ID = 'ANALYSIS'
const ADMIN_NAV_ID = 'ADMIN'
const TEAM_NAV_ID = 'TEAM'
const CALENDAR_NAV_ID = 'CALENDAR'
const MANAGEMENT_NAV_ID = 'MANAGEMENT'

type TabItem = {
  id: string
  routeName: string
  name: ReactNode
  icon: IconName
  path: string
  tabs: Array<{ name: ReactNode; path: string }>
}

// TODO:  It should be uploaded from routes constants
const tabList: TabItem[] = [
  {
    id: ANALYSIS_NAV_ID,
    routeName: routeNames.analysis.path,
    name: Translation('pages:analyser.title'),
    icon: 'clipboard',
    path: '/analysis/dashboard',
    tabs: [],
  },
  {
    id: TEAM_NAV_ID,
    routeName: routeNames.team.path,
    name: Translation('pages:team.title'),
    icon: 'shield',
    path: '/team/dashboard',
    tabs: [],
  },
]

const managementContextLists: TabItem = {
  id: MANAGEMENT_NAV_ID,
  routeName: routeNames.organization.path,
  icon: 'organization',
  name: 'Organizations',
  path: '/organization/',
  tabs: [],
}

const advancedTabList: TabItem[] = [
  {
    id: CALENDAR_NAV_ID,
    routeName: routeNames.calendar.path,
    name: Translation('pages:calendar.title'),
    icon: 'calendar-week',
    path: '/calendar',
    tabs: [],
  },
]

export const adminContextList: TabItem = {
  id: ADMIN_NAV_ID,
  routeName: routeNames.admin.path,
  name: Translation('pages:admin.title'),
  icon: 'person',
  path: '/admin/users',
  tabs: [],
}

const PUBLIC_ROUTES = ['/', '/redirecting', '/subscription/payment-status']

export const Nav = ({ isNavOpen, toggleNavDrawer }: NavProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const isAuthenticated = useIsAuthenticated()

  const { t } = useTranslation(['pages'])

  const { data: subscription } = useSubscription()
  const user = useUser({ enabled: isAuthenticated })
  const userStorage = useUserStorage(
    { keys: ['tabs', 'teamTabs'] },
    { enabled: isAuthenticated }
  )

  const [activeTab, setActiveTab] = useState<string | null>(null)

  const userNavTabs = useMemo(() => {
    return (
      userStorage.data?.tabs?.map(tab => ({
        name: tab.name,
        path: `/analysis/${tab.path}`,
      })) || [
        {
          name: t('pages:analyser.tab.dashboard'),
          path: '/analysis/dashboard',
        },
      ]
    )
  }, [userStorage.data, t])

  const userTeamNavTabs = useMemo(() => {
    return (
      userStorage.data?.teamTabs?.map(tab => ({
        name: tab.name,
        path: `/team/${tab.path}`,
      })) || [
        {
          name: t('pages:team.tab.dashboard'),
          path: '/team/dashboard',
        },
      ]
    )
  }, [userStorage.data, t])

  const resultTabs = useMemo(() => {
    const generalContextList = tabList.map(tab => {
      // TODO: Needs rework, this is not good at all!
      if (tab.id === ANALYSIS_NAV_ID) {
        return {
          ...tab,
          tabs: userNavTabs,
        }
      }
      if (tab.id === TEAM_NAV_ID) {
        return {
          ...tab,
          tabs: userTeamNavTabs,
        }
      }

      return tab
    })

    const additionalTabs: TabItem[] = []

    if (doesUserHaveTierAccess(user.data, 'advanced')) {
      additionalTabs.push(...advancedTabList)
    }

    if (
      doesUserHaveRole(user.data, [
        userRoles.COACH,
        userRoles.ADMIN,
        userRoles.ROOT,
      ])
    ) {
      additionalTabs.push(managementContextLists)
    }

    if (doesUserHaveRole(user.data, [userRoles.ADMIN, userRoles.ROOT])) {
      additionalTabs.push(adminContextList)
    }

    return [...generalContextList, ...additionalTabs]
  }, [userNavTabs, user.data, userTeamNavTabs])

  const handleNavigate = useCallback(
    (path: string) => {
      navigate(path)
    },
    [navigate]
  )

  if (
    ENABLE_SUBSCRIPTION &&
    (!subscription || subscription.status !== 'SUCCESSFUL')
  )
    return null

  if (PUBLIC_ROUTES.includes(location.pathname) || !isAuthenticated) {
    return null
  }

  return (
    <Drawer variant='permanent' open={isNavOpen}>
      <DrawerHeader>
        <LogoWrapper>
          <img src={LogoImageSrc} alt='logo' />
        </LogoWrapper>
      </DrawerHeader>
      <List>
        {resultTabs.map(({ name, icon, tabs, path, id, routeName }, index) => (
          <Fragment key={`${id}-${index}`}>
            <Tooltip
              arrow
              title={isNavOpen ? '' : <div>{name}</div>}
              placement='right'
              backgroundColor={theme.palette.darkest}
            >
              <ListItemButtonStyled
                active={activeTab === id && isNavOpen}
                sx={{
                  minHeight: 48,
                  justifyContent: isNavOpen ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={
                  tabs.length > 0 && isNavOpen
                    ? () => setActiveTab(prev => (prev !== id ? id : null))
                    : () => handleNavigate(path)
                }
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isNavOpen ? 3 : 'auto',
                    ml: 0.2,
                    justifyContent: 'space-between',
                  }}
                >
                  <NavIcon
                    name={icon}
                    active={location.pathname.startsWith(routeName)}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={name}
                  sx={{ opacity: isNavOpen ? 1 : 0, fontSize: '16px' }}
                />
                {tabs.length > 0 && (
                  <NavExpandIcon
                    sx={{ opacity: isNavOpen ? 1 : 0 }}
                    name='chevron-small-down'
                    chevronUp={activeTab !== id}
                  />
                )}
              </ListItemButtonStyled>
            </Tooltip>
            <Collapse in={activeTab === id && isNavOpen} timeout='auto'>
              {tabs.map((tab, tabIndex) => (
                <ListItemButtonStyled
                  key={`${tab.name}-${tabIndex}`}
                  active={activeTab === id}
                  onClick={() => handleNavigate(tab.path)}
                  sx={{
                    minHeight: 48,
                    px: 2.5,
                  }}
                >
                  <ListItemText
                    primary={tab.name}
                    sx={{
                      opacity: isNavOpen ? 1 : 0,
                      fontSize: '16px',
                      paddingLeft: '64px',
                    }}
                  />
                </ListItemButtonStyled>
              ))}
            </Collapse>
          </Fragment>
        ))}
      </List>
      <FlexStretch />
      <BottomBlock>
        <Divider />
        <ToggleWrapper>
          <ToggleIcon
            closed={!isNavOpen}
            name='chevron-bar-left'
            onClick={toggleNavDrawer}
          />
        </ToggleWrapper>
      </BottomBlock>
    </Drawer>
  )
}
